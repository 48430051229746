import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48')
];

export const server_loads = [2];

export const dictionary = {
		"/(main)": [~5,[2]],
		"/(main)/about-us": [~6,[2]],
		"/(main)/account": [7,[2,3]],
		"/(main)/account/orders": [8,[2,3]],
		"/(main)/account/settings": [9,[2,3]],
		"/(main)/account/support": [10,[2,3]],
		"/(main)/account/support/new": [~12,[2,3]],
		"/(main)/account/support/[slug]": [11,[2,3]],
		"/(main)/agreements/[agreementSlug]": [~13,[2]],
		"/(main)/auth/complete-registration": [14,[2]],
		"/(main)/auth/forgot-password": [15,[2]],
		"/(main)/auth/reset-password": [16,[2]],
		"/(main)/auth/sign-in": [17,[2]],
		"/(main)/auth/sign-out": [18,[2]],
		"/(main)/blog": [~19,[2]],
		"/(main)/blog/[slug]": [~20,[2]],
		"/(manage)/manage": [27,[4]],
		"/(manage)/manage/blog": [28,[4]],
		"/(manage)/manage/blog/new": [30,[4]],
		"/(manage)/manage/blog/[postId]": [29,[4]],
		"/(manage)/manage/categories": [31,[4]],
		"/(manage)/manage/categories/new": [33,[4]],
		"/(manage)/manage/categories/[categoryId]": [32,[4]],
		"/(manage)/manage/cms": [34,[4]],
		"/(manage)/manage/coupons": [35,[4]],
		"/(manage)/manage/coupons/new": [37,[4]],
		"/(manage)/manage/coupons/[couponId]": [36,[4]],
		"/(manage)/manage/inbox": [38,[4]],
		"/(manage)/manage/orders": [39,[4]],
		"/(manage)/manage/platforms": [40,[4]],
		"/(manage)/manage/platforms/new": [42,[4]],
		"/(manage)/manage/platforms/[platformId]": [41,[4]],
		"/(manage)/manage/products": [43,[4]],
		"/(manage)/manage/products/new": [45,[4]],
		"/(manage)/manage/products/[productId]": [44,[4]],
		"/(manage)/manage/support": [46,[4]],
		"/(manage)/manage/support/[slug]": [47,[4]],
		"/(manage)/manage/users": [48,[4]],
		"/(main)/payment/checkout": [21,[2]],
		"/(main)/payment/checkout/success": [~22,[2]],
		"/(main)/payment/failure": [23,[2]],
		"/(main)/services": [~24,[2]],
		"/(main)/services/[slug]": [~25,[2]],
		"/(main)/services/[slug]/[productCategorySlug]": [~26,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';